var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCompactView),expression:"!isCompactView"}],class:`${_vm.isB2b ? 'b2bMasthead header-wrap--multibrand' : null}
    ${_vm.isMultiBrand ? 'grid-container fluid' : null}`,attrs:{"id":"primaryNavigationBar","qaid":"nav-large-container"}},[_c('nav',{class:`primary-navigation top-bar stacked-for-small
      ${_vm.isB2b ? 'b2bMasthead__upper is-b2b justify-between' : ''}
      ${_vm.isMultiBrand ? 'header-toggle grid-x grid-margin-x' : ''}`,attrs:{"id":"primaryNavigation"}},[_c('div',{class:`top-bar-title
        ${_vm.isB2b ? 'sm:hidden m-0 lg:flex-grow' : ''}
        ${_vm.isMultiBrand && _vm.isElectraBrand ? 'cell large-2 top-bar-title--electra' : ''}`},[_vm._t("logo")],2),_vm._v(" "),_c('div',{class:`${_vm.isB2b ? 'b2bMasthead__nav-list-container' : 'top-bar-left'}
        ${_vm.isMultiBrand ? 'cell large-8' : null}`},[_vm._t("nav")],2),_vm._v(" "),(_vm.$slots.search)?_c('div',{class:_vm.isB2b ? 'flex align-middle' : 'search--multibrand cell large-2'},[_vm._t("search")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }