<template>
  <!-- INFO: Per isCompactView in Vuex, this container is only added to the DOM on large breakpoints and up -->
  <!-- **
  * TODO: In Phase 2 when Foundation is removed, change the v-show on this parent div to v-if
  * We only want either NavLargeContainer OR NavOverlayCompactLayout to appear in the DOM at a time 
  ** -->
  <div
    v-show="!isCompactView"
    id="primaryNavigationBar"
    qaid="nav-large-container"
    :class="`${isB2b ? 'b2bMasthead header-wrap--multibrand' : null}
      ${isMultiBrand ? 'grid-container fluid' : null}`"
  >
    <nav
      id="primaryNavigation"
      :class="`primary-navigation top-bar stacked-for-small
        ${isB2b ? 'b2bMasthead__upper is-b2b justify-between' : ''}
        ${isMultiBrand ? 'header-toggle grid-x grid-margin-x' : ''}`"
    >
      <div
        :class="`top-bar-title
          ${isB2b ? 'sm:hidden m-0 lg:flex-grow' : ''}
          ${isMultiBrand && isElectraBrand ? 'cell large-2 top-bar-title--electra' : ''}`"
      >
        <slot name="logo" />
      </div>
      <div
        :class="`${isB2b ? 'b2bMasthead__nav-list-container' : 'top-bar-left'}
          ${isMultiBrand ? 'cell large-8' : null}`"
      >
        <slot name="nav" />
      </div>
      <div v-if="$slots.search" :class="isB2b ? 'flex align-middle' : 'search--multibrand cell large-2'">
        <slot name="search" />
      </div>
    </nav>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'NavLargeContainer',
  provide: {
    idSuffix: '-large',
  },
  props: {
    isB2b: {
      type: Boolean,
      default: false,
    },
    isElectraBrand: {
      type: Boolean,
      default: false,
    },
    isMultiBrand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('global', ['isCompactView']),
  },
};
</script>
