import {userActions, userMutations} from '@/store/modules/user.js';
import AuthTokens from '@/utils/oauth/auth-tokens.js';
import {Url} from '@/utils/url.js';

const CLEAR_QUERY_PARAM = 'clear';
const POST_CODE = 'postCode';
const RETAILER = 'retailer';

const AuthHelpers = ({userEmail, customer}, dispatch, commit) => {
  async function handleLogin() {
    if (!userEmail) {
      return dispatch(userActions.LOGOUT_USER);
    }
    await AuthTokens.parseAuthTokens(userEmail);
    if (!customer) dispatch(userActions.LOAD_CURRENT_USER);
  }

  function withHandleClear(callback) {
    const params = new URLSearchParams(window?.location?.search);
    if (!params?.get(CLEAR_QUERY_PARAM)) return callback();

    // Clear user preferences between locale and host changes.
    dispatch(userActions.CLEAR_USER_PREFERENCES);

    // Get query params from electra when proceeding to cart.
    const postCode = params?.get(POST_CODE);
    const retailerId = params?.get(RETAILER);

    // Manually set retailer if cart is cleared
    dispatch(userActions.LOAD_SESSION_RETAILER, retailerId);
    commit(userMutations.SET_SELECTED_POSTCODE, postCode);
  }

  async function initSelectedRetailer() {
    return withHandleClear(() => {
      const params = Url.parse(window?.location?.href);
      const hasShopInSession = sessionStorage.getItem('shop_session');
      if (params?.shop) {
        dispatch(userActions.LOAD_REFERRED_SHOP_RETAILER);
      } else if (!hasShopInSession) {
        if (!userEmail) return dispatch(userActions.LOAD_ANONYMOUS_RETAILER);
        dispatch(userActions.LOAD_SELECTED_RETAILER);
      }
    });
  }

  async function initSelectedLocation() {
    return withHandleClear(() => {
      dispatch(userActions.INIT_SELECTED_LOCATION);
    });
  }

  return {handleLogin, initSelectedRetailer, initSelectedLocation};
};

export default AuthHelpers;
