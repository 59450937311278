class ProductGroups {
  /**
   * Initialize a new instance of ProductGroups class.
   * @param data
   * @param options
   */
  constructor(data, options = {}) {
    this.data = data;
    if (!data || !data.variants || !data.variantAttributesDirectory) {
      throw new Error('Product data is invalid.');
    }

    this.options = Object.assign(this.getDefaultOptions(), options);
    this.productsMap = this.getProductsMap();
    this.images = this.getPrimaryImages();
  }

  /**
   * Get default options.
   */
  getDefaultOptions() {
    return {
      isSkipArchiveProducts: true,
    };
  }

  /**
   * Get a list of primary images.
   * @return {{}}
   */
  getPrimaryImages() {
    const images = {};
    for (let image in this.data.images) {
      const primary = this.data.images[image].reduce((prev, curr) => (prev.weight < curr.weight ? prev : curr));
      images[image] = primary ? primary.assetId : '';
    }
    return images;
  }

  /**
   * Initialize object with a list of variants where variant's code is a key.
   * @return {{}}
   */
  getProductsMap() {
    const map = {};
    this.data.variants.forEach((variant) => {
      map[variant.code] = Object.assign(
        {
          skuQtyInSavedList: '',
          skuQtyInCart: '',
        },
        variant
      );
    });

    return map;
  }

  /**
   * Get general data which is needed for rendering group.
   * @return {{code: *, name: *, productType: *, marketingModelYear: *}}
   */
  getGroupData() {
    return {
      code: this.data.code,
      productType: this.data.productType,
      name: this.data.name,
      marketingModelYear: this.data.marketingModelYear,
      packageQty: parseInt(this.data.packageQty),
      totalShipmentNumber: this.data.totalShipmentNumber,
      isPreSeasonImmediateOrder: this.data.isPreseasonImmediateOrder,
    };
  }

  getVariantsForGroup(option, group) {
    const variants = this.data.variants
      .filter((v) => v.variantAttributes[group.code] === option.value)
      .map((v) => {
        const categorization = [];

        this.data.variantAttributesDirectory?.forEach((childGroup, index) => {
          // Ignore first element
          if (index === 0) return;

          const childValue = v.variantAttributes[childGroup.code];
          const childAttribute = childGroup.options.find((child) => child.value === childValue);

          categorization.push({
            name: childGroup.displayName,
            value: childAttribute.displayName,
          });
        });

        return {
          skuQtyInCart: '',
          ...v,
          categorization,
        };
      });

    return variants;
  }

  getProductGroups() {
    const group = this.data.variantAttributesDirectory[0];
    return group.options.map((option) => this.getProductGroupForColor(group, option));
  }

  getProductGroupForColor(group, option) {
    const sizes = this.getVariantsForGroup(option, group);
    const modelYearCurrent = this.getCurrentModelYear(sizes);
    const productGroup = {
      group: {
        meta: {
          code: group.code,
          type: group.type,
          options: {
            displayName: group.displayName,
            specFinishTranslated: group.specFinishTranslated,
          },
        },
        value: {
          children: [],
          code: group.code,
          value: option.value,
          options: {
            colorSwatchName: option.colorSwatchName,
            displayName: option.displayName,
            imageOption: option.imageOption,
            productCode: option.code,
            specFinishTranslated: option.specFinishTranslated,
          },
        },
        image: this.images?.[option.colorSwatchName],
      },
      modelYearCurrent,
      products: sizes,
      ...this.getGroupData(),
    };
    return productGroup;
  }

  getCurrentModelYear(products) {
    const variantYearList = [];
    let currentModelYear = '';

    const getModelYear = (products) => {
      products.forEach((product) => {
        variantYearList.push(product.marketingModelYear);
      });
      variantYearList.sort();
      let uniqueYear = [...new Set(variantYearList)];

      if (uniqueYear.length > 1) {
        currentModelYear = uniqueYear[uniqueYear.length - 1];
        return currentModelYear;
      } else {
        return (currentModelYear = uniqueYear[0]);
      }
    };
    return getModelYear(products);
  }
}

export default ProductGroups;
