import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';
import {resolveToastType} from '@/constants/meta-feedback-type';
import {CartApi} from '@/api/cart';
import {ApiDefaults} from '@/constants/api';
import {handleCartModifications} from '@/utils/cart/cart-modification-config';
import {UserStatus} from '@/constants/backend';
import {mutationAndActionHelper} from '@/utils/vuex-helpers';
import {PdlToastType} from '@/constants/pdl-toast-type';

export const ADD_SELECTED_PRODUCT = 'selectedProducts/ADD_SELECTED_PRODUCT';
export const UPDATE_SELECTED_PRODUCT = 'selectedProducts/UPDATE_SELECTED_PRODUCT';
export const REMOVE_SELECTED_PRODUCT = 'selectedProducts/REMOVE_SELECTED_PRODUCT';
export const DELETE_SELECTED_PRODUCTS = 'selectedProducts/DELETE_SELECTED_PRODUCTS';
export const CLEAR_SELECTED_PRODUCTS = 'selectedProducts/CLEAR_SELECTED_PRODUCTS';

const actionTypes = Object.freeze({
  GET_CART_DATA: 'getCartData',
  GET_CART_DATA_WITH_VALIDATION: 'getCartDataWithValidation',
  UPDATE_CART_DEALER_AND_MODE: 'updateCartDealerAndMode',
  UPDATE_CART_DEALER: 'updateCartDealer',
  UPDATE_CART_DEALER_AND_ADDRESS: 'updateCartDealerAndAddress',
  UPDATE_CART_ADDRESS: 'updateCartAddress',
  UPDATE_CART_EMAIL: 'updateCartEmail',
  UPDATE_CART_ENTRY_OPTIONS: 'updateCartEntryOptions',
  APPLY_PROMOTION: 'applyPromotion',
  REMOVE_PROMOTION: 'removePromotion',
  VALIDATE_CART: 'validateCart',
  GET_VALID_RETAILERS_FOR_CART: 'getValidRetailersForCart',
  GET_FULFILLMENT_OPTIONS: 'getFulfillmentOptions',
  REVERT_TO_ANONYMOUS_CART: 'revertToAnonymousCart',
});

const MutationTypes = Object.freeze({
  SET_CART_FULFILLMENT_SPEED: 'setCartFulfillmentSpeed',
  SET_CART_FULFILLMENT_OPTIONS: 'setCartFulfillmentOptions',
  SET_HAS_UPGRADED_SHIPPING: 'setHasUpgradedShipping',
  SET_HAS_CLICKED_PROCEED_WITHOUT_DELIVERY_MODE_SELECTED: 'setHasClickedProceedWithoutDeliveryModeSelected',
});

export const cartActions = Object.entries(actionTypes).reduce((actionObj, [key, value]) => {
  actionObj[key] = `cart/${value}`;
  return actionObj;
}, {});

export const cartMutations = mutationAndActionHelper(MutationTypes, 'cart');

const cartModule = {
  namespaced: true,

  state: {
    bikes: [],
    cartData: null,
    trackEntries: null,
    dealerAddress: null,
    parts: [],
    customWaterBottles: [],
    projectOneBikes: [],
    cartSummary: {
      bikes: {},
      parts: {},
      customWaterBottles: {},
      projectOneBikes: {},
      total: '',
      hasAllocatedItems: false,
    },
    undoEntries: null,
    fetchCallback: null,
    selectedProducts: [],
    hasAllocatedItemsProcessing: false,
    hasCartValidationError: false,
    isCartLoading: false,
    cartFulfillmentOptions: [],
    cartFulfillmentSpeed: 'GROUND',
    hasUpgradedShipping: false,
    hasClickedProceedWithoutDeliveryModeSelected: false,
  },

  getters: {
    entryNumbers: (state, getters, rootState) => {
      let result = state.cartData?.entries.map((entry) => entry.entryNumber);
      if (!result?.length) {
        result = rootState.checkout.b2cCartData?.map((entry) => entry.entryNumber);
      }
      return result;
    },
    selectedProducts: (state) => state.selectedProducts,
    selectedProductsBikeCount: (state) =>
      state.selectedProducts.filter((product) => product.productType === 'bike').length,
    selectedProductsAccessoriesCount: (state) =>
      state.selectedProducts.filter((product) => product.productType === 'am').length,
    trackCartEntries(state) {
      if (!state.cartData?.entries.length) return [];

      //Handles updating trackEntries when jquery ajax replaces the dom elements in cart.
      if (state.trackEntries !== null && state.trackEntries.includes('id')) {
        return state.trackEntries;
      }

      //Cart entry data processed for analytics
      let mappedEntries = state.cartData?.entries.map((entry) => {
        let variantMatrix = entry.product.variantMatrix; // Hybris array matrix splits color and size
        let variantColor = variantMatrix[0].variantValueCategory.name;
        let variantSize = variantMatrix.length > 1 ? variantMatrix[1].variantValueCategory.name : 'No Size Available';

        return {
          id: entry.product.code,
          name: entry.product.name,
          price: entry.totalPrice.value,
          brand: entry.product.brandNameFull,
          category: entry.product.defaultCategory,
          variant: variantColor,
          size: variantSize,
          quantity: entry.quantity,
        };
      });

      return mappedEntries;
    },
    isAnonymousCart(state) {
      return state.cartData?.user?.uid === UserStatus.ANONYMOUS_USER;
    },
  },

  mutations: {
    setBikes(state, payload) {
      Vue.set(state, 'bikes', payload);
    },

    setParts(state, payload) {
      Vue.set(state, 'parts', payload);
    },

    setCustomWaterBottles(state, payload) {
      Vue.set(state, 'customWaterBottles', payload);
    },

    setCartData(state, payload) {
      state.cartData = payload;
    },

    setTrackEntries(state, payload) {
      state.trackEntries = payload;
    },

    setDealerAddress(state, payload) {
      state.dealerAddress = payload;
    },

    setProjectOneBikes(state, payload) {
      Vue.set(state, 'projectOneBikes', payload);
    },

    setCartSummary(state, payload) {
      Vue.set(state, 'cartSummary', payload);
    },

    setUndoEntries(state, payload) {
      Vue.set(state, 'undoEntries', payload);
    },

    setFetchCallback(state, payload) {
      Vue.set(state, 'fetchCallback', payload);
    },

    setHasAllocatedItemsProcessing(state, payload) {
      state.hasAllocatedItemsProcessing = payload;
    },

    setHasValidationError(state, payload) {
      state.hasCartValidationError = payload;
    },

    setIsCartLoading(state, value) {
      state.isCartLoading = value;
    },

    [MutationTypes.SET_HAS_UPGRADED_SHIPPING](state, payload) {
      state.hasUpgradedShipping = payload;
    },

    [MutationTypes.SET_HAS_CLICKED_PROCEED_WITHOUT_DELIVERY_MODE_SELECTED](state, payload) {
      state.hasClickedProceedWithoutDeliveryModeSelected = payload;
    },

    [ADD_SELECTED_PRODUCT](state, payload) {
      if (!state.selectedProducts.some((product) => product.cartEntryPk === payload.cartEntryPk)) {
        state.selectedProducts.push(payload);
      }
    },
    [UPDATE_SELECTED_PRODUCT](state, payload) {
      if (payload.lineNote) {
        Vue.set(
          state.selectedProducts[
            state.selectedProducts.findIndex((product) => product.cartEntryPk === payload.cartEntryPk)
          ],
          'lineNote',
          payload.lineNote
        );
      }
      if (payload.qty) {
        Vue.set(
          state.selectedProducts[
            state.selectedProducts.findIndex((product) => product.cartEntryPk === payload.cartEntryPk)
          ],
          'qty',
          Number(payload.qty)
        );
      }
    },
    [REMOVE_SELECTED_PRODUCT](state, payload) {
      state.selectedProducts = payload;
    },
    [DELETE_SELECTED_PRODUCTS](state, payload) {
      state.selectedProducts = payload;
    },
    [CLEAR_SELECTED_PRODUCTS](state, payload) {
      state.selectedProducts = payload;
    },
    [MutationTypes.SET_CART_FULFILLMENT_OPTIONS](state, payload) {
      state.cartFulfillmentOptions = payload;
    },
    [MutationTypes.SET_CART_FULFILLMENT_SPEED](state, payload) {
      state.cartFulfillmentSpeed = payload;
    },
  },

  actions: {
    addSelectedProduct({commit}, payload) {
      commit(ADD_SELECTED_PRODUCT, payload);
    },
    updateSelectedProduct({commit}, payload) {
      commit(UPDATE_SELECTED_PRODUCT, payload);
    },
    removeSelectedProduct({state, commit}, payload) {
      commit(
        REMOVE_SELECTED_PRODUCT,
        state.selectedProducts.filter((product) => product.cartEntryPk !== payload)
      );
    },
    deleteSelectedProducts({state, dispatch, commit}, payload) {
      if (payload === 'bikes' || payload === 'projectOneBikes') {
        dispatch(
          'updateCart',
          state.selectedProducts
            .filter((product) => product.productType === 'bike')
            .map((product) => {
              return {
                sku: product.sku,
                cartEntryPk: product.cartEntryPk,
                qty: 0,
              };
            })
        );
        commit(
          DELETE_SELECTED_PRODUCTS,
          state.selectedProducts.filter((product) => product.productType !== 'bike')
        );
      } else {
        dispatch(
          'updateCart',
          state.selectedProducts
            .filter((product) => product.productType !== 'bike')
            .map((product) => {
              return {
                sku: product.sku,
                qty: 0,
                cartEntryPk: product.cartEntryPk,
                productRecipeID: product.productRecipeID,
              };
            })
        );
        commit(
          DELETE_SELECTED_PRODUCTS,
          state.selectedProducts.filter((product) => product.productType === 'bike')
        );
      }
    },
    clearSelectedProducts({state, commit}, payload) {
      if (payload === 'bikes' || payload === 'projectOneBikes') {
        commit(
          CLEAR_SELECTED_PRODUCTS,
          state.selectedProducts.filter((product) => product.productType !== 'bike')
        );
      } else if (payload === 'parts') {
        commit(
          CLEAR_SELECTED_PRODUCTS,
          state.selectedProducts.filter((product) => product.productType !== 'am' || product.productRecipeID)
        );
      } else if (payload === 'customWaterBottles') {
        commit(
          CLEAR_SELECTED_PRODUCTS,
          state.selectedProducts.filter((product) => product.productType !== 'am' || !product.productRecipeID)
        );
      }
    },
    fetchCart(context) {
      return storefrontInstance
        .get('/cart/cartInfo?includes=bikes,parts,customWaterBottles,projectOne')
        .then(({data}) => {
          if (data && data.data) {
            if (data.data.cartSummary) {
              context.commit('setBikes', data.data.bikes);
              context.commit('setParts', data.data.parts);
              context.commit('setCustomWaterBottles', data.data.customWaterBottles);
              context.commit('setProjectOneBikes', data.data.projectOneBikes);
              context.commit('setCartSummary', data.data.cartSummary);
            } else {
              context.commit('setBikes', []);
              context.commit('setParts', []);
              context.commit('setCustomWaterBottles', []);
              context.commit('setProjectOneBikes', []);
              context.commit('setCartSummary', {});
            }
          }

          if (context.state.fetchCallback) {
            context.state.fetchCallback();
          }
        });
    },

    updateCart({dispatch}, products, deleteSingleProductFlag = false) {
      return storefrontInstance
        .put(`/cart/entries/`, {cartEntries: products})
        .then((response) => {
          // Display error toast if attempting to add more products than the distributor containers can hold
          const feedback = response?.data?.meta?.feedback;
          if (feedback?.message?.basePropertyKey === 'distributor.B2B.skuGrid.containerFull') {
            this.$notify({
              type: resolveToastType(feedback?.type),
              message: feedback?.message?.basePropertyValue,
              showClose: true,
              duration: feedback?.type?.toastDuration,
            });
          }

          return response;
        })
        .finally(() => {
          if (!deleteSingleProductFlag) {
            dispatch('fetchCart');
          }
        });
    },

    removeProducts({state, commit, dispatch}, partTypeKey) {
      let productsToRemoveFromCart = [];
      if (partTypeKey === 'bikes') {
        const bikeList = state.selectedProducts.filter((product) => product.productType === 'bike');
        commit('setUndoEntries', bikeList);
        productsToRemoveFromCart = bikeList.map((product) => {
          return {
            sku: product.sku,
            cartEntryPk: product.cartEntryPk,
            qty: 0,
          };
        });
      } else {
        const accessoriesList = state.selectedProducts.filter((product) => product.productType !== 'bike');
        commit('setUndoEntries', accessoriesList);
        productsToRemoveFromCart = accessoriesList.map((product) => {
          return {
            sku: product.sku,
            cartEntryPk: product.cartEntryPk,
            qty: 0,
          };
        });
      }

      dispatch('clearSelectedProducts', partTypeKey);
      if (productsToRemoveFromCart.length) {
        storefrontInstance.put(`/cart/entries/`, {cartEntries: productsToRemoveFromCart}).finally(() => {
          dispatch('fetchCart');
        });
      }
    },

    undoRemove(context, activeEntries) {
      storefrontInstance.put(`/cart/entries/batch/`, {items: activeEntries}).finally(() => {
        context.dispatch('fetchCart');
      });
      context.commit('setUndoEntries', null);
    },

    async [actionTypes.GET_CART_DATA]({commit, rootGetters}, fields) {
      commit('setIsCartLoading', true);
      try {
        const cartPayload = rootGetters['backend/occUrlParams'];
        const cartParams = rootGetters['backend/cartParams'];
        const cartData = await CartApi.getCart({...cartPayload, ...cartParams}, fields);
        commit('setCartData', cartData);
        return cartData;
      } catch (error) {
        console.error('Error retrieving cart data.', error);
      } finally {
        commit('setIsCartLoading', false);
      }
    },
    async [actionTypes.GET_CART_DATA_WITH_VALIDATION]({dispatch}, fields) {
      await dispatch(actionTypes.VALIDATE_CART);
      dispatch(actionTypes.GET_CART_DATA, fields);
    },
    async [actionTypes.VALIDATE_CART]({rootGetters}) {
      const cartPayload = rootGetters['backend/occUrlParams'];
      if (!cartPayload.cartId) return;
      const {cartModifications} = await CartApi.validateCart(cartPayload);
      if (cartModifications?.length) {
        handleCartModifications(cartModifications);
        return true;
      }
      return false;
    },
    async [actionTypes.GET_VALID_RETAILERS_FOR_CART]({commit, rootGetters, rootState, state}) {
      try {
        const hasValidDeliveryMode = state.cartFulfillmentOptions.every((cfo) =>
          cfo?.fulfillmentOptions.some((fo) => fo?.deliveryModes.length)
        );
        if (hasValidDeliveryMode) return;
        const occUrlParams = rootGetters['backend/occUrlParams'];
        const postCode = rootState.user.selectedLocation?.postcode;
        const retailers = await CartApi.getValidRetailersForCart(occUrlParams, postCode);
        const supportedRetailer = retailers?.stores?.[0];

        commit('user/setSelectedRetailer', supportedRetailer, {root: true});
        window.vm.toast({
          type: PdlToastType.DEFAULT,
          message: window.VueI18n.t('checkout.multi.pickupInStore.notSoldMessage'),
          showClose: true,
          duration: 0,
        });
      } catch (error) {
        console.error('Error fetching stores, please try again...');
      }
    },
    async [actionTypes.UPDATE_CART_DEALER_AND_MODE]({commit, rootGetters}, payload) {
      try {
        const response = await CartApi.updateCartDealerAndMode(rootGetters['backend/occUrlParams'], payload);
        if (payload?.dealerCode) {
          const dealerAddress = await CartApi.getDealerAddress(payload.dealerCode, ApiDefaults.FULL);
          commit('setDealerAddress', dealerAddress);
        }
        return response;
      } catch (error) {
        console.error('Error updating cart dealer address or delivery mode.', error);
      }
    },
    async [actionTypes.UPDATE_CART_DEALER]({dispatch, rootGetters}, payload) {
      try {
        const cartParams = rootGetters['backend/occUrlParams'];
        if (!cartParams.userId || !cartParams.cartId) return;

        const response = await CartApi.updateCartDealer(cartParams, payload);
        dispatch(actionTypes.GET_CART_DATA);
        return response;
      } catch (error) {
        console.error('Error updating POS dealer on cart.', error);
      }
    },
    [actionTypes.GET_FULFILLMENT_OPTIONS]({rootGetters, state, getters}) {
      const cartParams = rootGetters['backend/occUrlParams'];
      if (!cartParams.userId || !cartParams.cartId) return;
      let promises = [];
      getters.entryNumbers?.forEach((entryNumber) => {
        promises.push(
          CartApi.getCartEntryFulfillmentOptions(cartParams.userId, cartParams.cartId, entryNumber).then((response) => {
            let index = state.cartFulfillmentOptions?.findIndex((option) => option.orderEntryId === entryNumber);
            if (index > -1) {
              state.cartFulfillmentOptions.splice(index, 1);
            }
            state.cartFulfillmentOptions.push(response.data);
          })
        );
      });
      return Promise.all(promises);
    },
    async [actionTypes.UPDATE_CART_ENTRY_OPTIONS]({dispatch, rootGetters}, payload) {
      try {
        const occParams = rootGetters['backend/occUrlParams'];
        if (!occParams.userId || !occParams.cartId) return;

        const response = await CartApi.updateCartEntryOptions(occParams.userId, occParams.cartId, payload);
        dispatch(actionTypes.GET_CART_DATA);

        return response;
      } catch (error) {
        console.error('Error updating cart entry options.', error);
      }
    },
    async [actionTypes.UPDATE_CART_EMAIL]({rootGetters}, email) {
      try {
        const cartParams = rootGetters['backend/occUrlParams'];
        if (!cartParams.userId || !cartParams.cartId) return;

        const response = await CartApi.updateCartEmail(cartParams, email);
        return response;
      } catch (error) {
        console.error('Error updating cart email.', error);
      }
    },

    async [actionTypes.UPDATE_CART_DEALER_AND_ADDRESS]({dispatch, rootGetters}, {dealer, address}) {
      try {
        let cartParams = rootGetters['backend/occUrlParams'];
        if (!cartParams.userId || !cartParams.cartId) return;
        if (dealer) {
          await CartApi.updateCartDealer(cartParams, dealer);
        }
        if (address) {
          await CartApi.updateCartAddress(cartParams, address);
        }
        dispatch(actionTypes.GET_CART_DATA);
      } catch (error) {
        console.error('Error updating cart email.', error);
      }
    },

    async [actionTypes.UPDATE_CART_ADDRESS]({dispatch, rootGetters}, payload) {
      try {
        const response = await CartApi.updateCartAddress(rootGetters['backend/occUrlParams'], payload);
        dispatch(actionTypes.GET_CART_DATA);
        return response;
      } catch (error) {
        console.error('Error updating cart address.', error);
      }
    },

    async deleteCartAddress({rootGetters}) {
      try {
        const response = await CartApi.deleteCartAddress(rootGetters['backend/occUrlParams']);
        return response;
      } catch (error) {
        console.error('Error deleting cart address.', error);
      }
    },

    async [actionTypes.APPLY_PROMOTION]({rootGetters, getters, state, commit, dispatch}, couponCode) {
      if (getters.isAnonymousCart) await dispatch(actionTypes.UPDATE_CART_EMAIL);

      const {length: initialOrderPromoCount} = state.cartData?.appliedOrderPromotions;
      const {length: initialProductPromoCount} = state.cartData?.appliedProductPromotions;
      const cartPayload = rootGetters['backend/occUrlParams'];
      const cartParams = rootGetters['backend/cartParams'];

      await CartApi.applyPromotionCode(cartPayload, couponCode);
      const updatedCart = await CartApi.getCart({...cartPayload, ...cartParams});

      if (
        updatedCart.appliedOrderPromotions?.length > initialOrderPromoCount ||
        updatedCart.appliedProductPromotions?.length > initialProductPromoCount
      ) {
        return commit('setCartData', updatedCart);
      }

      await CartApi.removePromotionCode(cartPayload, couponCode);
      throw new Error();
    },

    async [actionTypes.REMOVE_PROMOTION]({rootGetters, dispatch}, couponCode) {
      await CartApi.removePromotionCode(rootGetters['backend/occUrlParams'], couponCode);
      return dispatch(actionTypes.GET_CART_DATA);
    },
    async [actionTypes.REVERT_TO_ANONYMOUS_CART]({rootGetters}) {
      return await CartApi.revertToAnonymousCart(rootGetters['backend/occUrlParams']);
    },
  },
};

export default cartModule;
